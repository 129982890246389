import $ from 'jquery';

import './sass/style.scss';
import './js/app.js';

$(document).on('change', '#select_all', function () {
    if ($(this).is(':checked')) {
        $(this).parent().parent().find('input[type=checkbox]').prop('checked', true);
    } else {
        $(this).parent().parent().find('input[type=checkbox]').prop('checked', false);
    }
});

$('#show_coupon_form').on('click', () => {
    $('#coupon_form').slideToggle();
});

$(document).on('change', '.variation-switcher', function () {
    const cart_item_key = $(this).data('cart-item-key');
    const variation_id = $(this).find('input[type="radio"]:checked').val();

    $.ajax({
        type: 'POST',
        url: jsData.ajaxUrl,
        data: {
            action: 'change_variation_in_cart',
            cart_item_key,
            variation_id,
        },
        success(response) {
            if (response.success) {
                $(document.body).trigger('update_checkout');
            } else {
                alert('Error: Unable to change variation.');
            }
        },
    });
});

$('a.Button.slideable').on('click', function (e) {
    e.preventDefault();

    const target = $(this).attr('href');
    const hashIndex = target.indexOf('#');

    if (hashIndex !== -1) {
        const targetId = target.substring(hashIndex);

        if ($(targetId).length) {
            const headerHeight = $('.Header').outerHeight();
            const targetPosition = $(targetId).offset().top - headerHeight;

            $('html, body').animate(
                {
                    scrollTop: targetPosition,
                },
                400,
            );
        }
    }
});

document.addEventListener('DOMContentLoaded', () => {
    const counters = document.querySelectorAll('[data-component="counter"]');

    function startCounter(countDownDate, counter, x) {
        const now = new Date().getTime();
        const distance = countDownDate - now;
        const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);

        function addLeadingZero(num) {
            return num < 10 ? `0${num}` : num;
        }

        counter.innerHTML = `
            <div class="flex items-end !text-black text-[12px] font-bold">
                ${hours} 
                <div class="!text-[11px] ml-[2px] !font-medium !text-black block "> godzin</div>
            </div>
            <div class="flex items-end   !text-black text-[12px] font-bold">
                ${addLeadingZero(minutes)} 
                <div class="!text-[11px] ml-[2px] !font-medium !text-black block "> minut</div>
            </div>
            <div class="flex items-end   !text-black text-[12px] font-bold">
                ${addLeadingZero(seconds)} 
                <div class="!text-[11px] ml-[2px]  !font-medium !text-black block "> sekund</div>
            </div>
        `;

        if (distance < 0) {
            clearInterval(x);
            counter.innerHTML = '';
        }
    }

    counters?.forEach((counter) => {
        const now = new Date();
        const countDownDate = new Date(
            now.getFullYear(),
            now.getMonth(),
            now.getDate() + 1,
        ).getTime();

        const x = setInterval(() => {
            startCounter(countDownDate, counter, x);
        }, 1000);

        startCounter(countDownDate, counter, x);
    });

    const faqItemBtns = document.querySelectorAll('.faq-item-btn');
    const faqSections = document.querySelectorAll('.faq-section');

    function addActiveClass(button) {
        faqItemBtns.forEach((btn) => btn.classList.remove('active-faq-item-btn'));
        faqSections.forEach((section) => {
            section.classList.add('hidden');
            section.classList.remove('hidden');
        });

        const sectionId = button.getAttribute('href').replace('#', '').trim();

        faqSections.forEach((section) => {
            section.classList.add('hidden');
            if (section.getAttribute('data-id') === sectionId) {
                section.classList.remove('hidden');
            }
        });

        button.classList.add('active-faq-item-btn');

        $('html, body').animate({
            scrollTop: $(`[data-id="${sectionId}"]`).offset().top - $('.Header').height() - 20,
        });
    }

    faqItemBtns.forEach((button) => {
        button.addEventListener('click', (event) => {
            event.preventDefault();
            addActiveClass(button);
        });
    });

    window.addEventListener('scroll', function () {
        const scrollPosition = window.scrollY + window.innerHeight;
        const documentHeight = document.documentElement.scrollHeight;
        const scrollPercentage = (scrollPosition / documentHeight) * 100;

        const button = document.getElementById('go-top-button');
        if (!button) {
            return;
        }
        if (scrollPercentage >= 95) {
            button.style.display = 'flex';
        } else {
            button.style.display = 'none';
        }
    });

    $('#go-top-button').on('click', () => {
        $('html, body').animate({ scrollTop: 0 });
        return false;
    });
});
